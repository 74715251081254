.Grade .buttons {
  text-align: center;
}

.Grade .buttons > * {
  margin: 1em;
}

.Grade .buttons {
  position: sticky;
  background-color: #fff;
  bottom: 0;
  /* bottom: 20px; */
}

.Grade .multiDeduct {
  width: 20px;
}
